import { Component, Input } from "@angular/core";
import { TypeManagerDecorator } from "../../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-pricing-tax")
@Component({
    selector: "s25-ng-pricing-tax",
    template: `
        <div>
            @for (item of taxData; let i = $index; track i) {
                <div class="tax-data-item">
                    <div class="tax-name">{{ item.itemName }}:</div>
                    <div>{{ item.itemValue }}</div>
                </div>
            }
        </div>
    `,
    styles: `
        .tax-data-item {
            width: 9em;
        }
        .tax-name {
            font-size: 90%;
        }
    `,
})
export class S25PricingTaxComponent {
    @Input() taxData: any;
}
