import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { TypeManagerDecorator } from "../../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-pricing-tooltip")
@Component({
    selector: "s25-ng-pricing-tooltip",
    template: `@if (init && data.value !== "$0.00") {
        <div class="pricing-tooltip-wrapper c-margin-bottom--single">
            <div class="tooltip-amount">
                {{ data.value }}
                <s25-ng-info-message [isPopover]="true">
                    <h4>{{ messageHeader }}</h4>
                    <p>{{ message }}</p>
                </s25-ng-info-message>
            </div>
        </div>
    }`,
    styles: `
        :host ::ng-deep .c-svgIcon {
            position: relative;
            bottom: 1px;
        }

        .pricing-tooltip-wrapper {
            display: flex;
            position: relative;
        }

        :host ::ng-deep s25-ng-info-message {
            position: unset;
            transition: unset;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25PricingTooltipComponent implements OnInit {
    @Input() data: { type: "listPrice" | "tax"; value: string };

    message: string;
    messageHeader: string;
    init: boolean;

    constructor(private cd: ChangeDetectorRef) {}

    ngOnInit() {
        if (this.data.type === "listPrice") {
            this.message =
                "The total for all occurrence adjustments is added to the subtotal of pre-adjustment Occurrences as well as the list prices for items not associated with occurrences to arrive at a total list price for the organization.";
            this.messageHeader = "Total Occurrence Adjustments";
        } else {
            this.message =
                "Tax amounts for items associated with occurrences are added to the sum of taxes for items not associated with occurrences to arrive at a total tax amount for the organization.";
            this.messageHeader = "Total Tax for Occurrence Items";
        }

        this.init = true;
    }
}
