import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { S25LoadingApi } from "../../../s25-loading/loading.api";
import { PricingService } from "../../../../services/pricing.service";
import { S25Util } from "../../../../util/s25-util";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { PricingModel, PricingOrg, UpdateData } from "../../../../pojo/Pricing";
import { PriceSheetI } from "../../../../pojo/RateScheduleI";

@TypeManagerDecorator("s25-ng-pricing-rate-group")
@Component({
    selector: "s25-ng-pricing-rate-group",
    template: `@if (init) {
        <div>
            <s25-loading-inline [model]="{}"></s25-loading-inline>
            @if (
                modelBean.canEdit &&
                modelBean.canEditPricing &&
                !modelBean.listItems &&
                !modelBean.isOccurrence &&
                modelBean.rateGroups.length > 1
            ) {
                <div>
                    <select
                        [id]="!modelBean.bill_item_id ? 'allRateGroups' : ''"
                        [attr.aria-label]="modelBean.bill_item_id && 'Select Rate Group'"
                        class="cn-form__control"
                        [(ngModel)]="modelBean.selectedRateGroup"
                        (change)="rateGroupChange()"
                    >
                        @for (item of modelBean.rateGroups; track item) {
                            <option [ngValue]="item">
                                {{ item.rate_group_name }}
                            </option>
                        }
                    </select>
                </div>
            }
            @if (
                !modelBean.listItems &&
                (!modelBean.canEdit ||
                    !modelBean.canEditPricing ||
                    modelBean.isOccurrence ||
                    modelBean.rateGroups.length <= 1 ||
                    modelBean.isSummary)
            ) {
                <span>{{ modelBean.itemName }}</span>
            }
        </div>
    }`,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25PricingRateGroupComponent implements OnInit {
    @Input() modelBean: PricingModel;
    @Input() orgLists: PricingOrg[];

    @Output() updateRateGroup: EventEmitter<UpdateData> = new EventEmitter<UpdateData>();

    init: boolean = false;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        if (this.modelBean?.rateGroups?.length > 0 && !this.modelBean.isSummary) {
            this.modelBean.rateGroups = [
                { rate_group_id: -1, rate_group_name: "Choose a Rate Group", defn_state: 1 },
                ...this.modelBean.rateGroups,
            ];

            this.modelBean.selectedRateGroup =
                S25Util.array.getByProp(this.modelBean.rateGroups, "rate_group_id", this.modelBean.rate_group_id) ||
                this.modelBean.rateGroups[0];

            if (this.modelBean.selectedRateGroup.rate_group_id > 0) this.modelBean.rateGroups.shift();
        }

        if (
            this.modelBean.fls?.ML_PRICE !== "F" &&
            this.modelBean.rateGroups?.length > 0 &&
            !this.modelBean.isSummary
        ) {
            //only active rate groups if ML_PRICE is not F
            this.modelBean.rateGroups = this.modelBean.rateGroups.filter((group: PriceSheetI) => {
                return (
                    parseInt(group.rate_group_id) === parseInt(this.modelBean.rate_group_id) ||
                    this.modelBean.fls === "F" ||
                    S25Util.toBool(group.defn_state)
                );
            });
        }

        this.init = true;

        this.cd.detectChanges();
    }

    async rateGroupChange() {
        if (this.modelBean.selectedRateGroup.rate_group_id > 0) {
            S25LoadingApi.init(this.elementRef.nativeElement);
            this.modelBean.itemName = this.modelBean.selectedRateGroup.rate_group_name;

            let promise;
            if (this.modelBean.isEventLevel) {
                promise = PricingService.putPricingEventRateGroup(
                    this.modelBean.eventId,
                    this.modelBean.selectedRateGroup.rate_group_id,
                    this.modelBean.evBillId,
                );
            } else {
                promise = PricingService.putPricingLineItemRateGroup(
                    this.modelBean.eventId,
                    this.modelBean.selectedRateGroup.rate_group_id,
                    this.modelBean.bill_item_id,
                    this.modelBean.bill_item_type_id,
                    this.modelBean.ev_dt_profile_id,
                    this.modelBean.evBillId,
                );
            }

            try {
                const response = await promise;

                const updatedData = PricingService.processTableUpdate(response, this.modelBean);

                this.updateRateGroup.emit(updatedData);

                S25LoadingApi.destroy(this.elementRef.nativeElement);
            } catch (error) {
                S25LoadingApi.destroy(this.elementRef.nativeElement);
                console.error(error);
                alert("There was an error while saving the rate group");
            }
        }
    }
}
